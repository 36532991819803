import React from 'react';
import { useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';
import { Seo } from '../../components/seo';
import Layout from '../../components/layout';
import PicturesSlider from '../../components/slider/pictures-slider';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Audio from '../../components/audio';

const WorkPage = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Layout navBarLink='navBarLinkWorkPage' navBarLinkWork='navBarLinkWorkPage'>
      <Seo title={data.work.title} />
      <article
        className='pb-6'
        onClick={() => {
          open && setOpen(!open);
        }}
      >
        <div className=''>
          <PicturesSlider
            pictures={data.work.visuals}
            videos={data.work.video}
          />
        </div>

        <div className='block my-12 max-w-3xl mx-auto'>
          <p className='mb-5 text-base lg:text-lg font-semibold'>
            {data.work.title}
          </p>
          <p>
            <MDXRenderer>{data.work.description.childMdx.body}</MDXRenderer>
          </p>
        </div>

        {data.work.audio && (
          <div>
            {data.work.audio.map((audio, i) => (
              <Audio audioTitle={audio.title} audioSrcURL={audio.url} key={i} />
            ))}
          </div>
        )}
        <Link
          to='/work'
          className='block tracking-widest text-sm lg:text-xl lowercase underline pb-6 navBarLinkWork '
        >
          back
        </Link>

        {/* <Link
          to='/work'
          className='hidden lg:block fixed bottom-24 tracking-widest text-sm lg:text-xl lowercase hover:underline'
        >
          back
        </Link>

        <button
          className='hidden lg:block fixed bottom-24 right-24 tracking-widest text-sm lg:text-xl lowercase hover:underline'
          onClick={() => {
            setOpen(!open);
          }}
        >
          info
        </button>

        <section className={open ? 'block' : 'hidden'}>
          <div className='bg-white absolute bottom-0 w-2/5 right-16 z-100 max-h-full'>
            <div className='px-5 py-12 '>
              <div className='flex mb-5 items-start'>
                <p className='text-sm lg:text-base'>{data.work.title}</p>
                <button
                  className='ml-auto hidden lg:inline'
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  x
                </button>
              </div>

              <p>
                <MDXRenderer>{data.work.description.childMdx.body}</MDXRenderer>
              </p>
            </div>
          </div>
        </section> */}
      </article>
    </Layout>
  );
};

export default WorkPage;

export const query = graphql`
  query WorksBySlug($slug: String) {
    work: contentfulWork(slug: { eq: $slug }) {
      id
      slug
      title
      type
      description {
        childMarkdownRemark {
          html
        }
        childMdx {
          body
        }
      }
      visuals {
        gatsbyImageData(
          formats: AUTO
          placeholder: BLURRED
          layout: CONSTRAINED
          height: 800
          quality: 65
        )
      }
      video {
        title
        url
      }
      audio {
        title
        url
      }
    }
  }
`;
