import React from 'react';
import PicturesSlide from './slide';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Keyboard, A11y } from 'swiper';
import Video from '../video';
import './swiper.css';

const PicturesSlider = ({ pictures, videos }) => {
  return (
    <div className=" ">
      <Swiper
        modules={[Keyboard, Navigation, Pagination, A11y]}
        slidesPerView={'1'}
        navigation={true}
        keyboard={{
          enabled: true,
        }}
      >
        {videos && (
          <div className="">
            <SwiperSlide>
              <Video videoSrcURL={videos.url} videoTitle={videos.title} />
            </SwiperSlide>
          </div>
        )}
        {pictures.map((picture) => {
          return (
            <div className="">
              <SwiperSlide>
                <PicturesSlide image={picture.gatsbyImageData} />
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

export default PicturesSlider;
