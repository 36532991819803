import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const PicturesSlide = ({ image }) => {
  return (
    <div className="flex">
      <div className="m-auto ">
        <GatsbyImage
          alt={'Picture'}
          image={image}
          objectFit={'contain'}
          // style={{ height: '800px' }}
        />
      </div>
    </div>
  );
};

export default PicturesSlide;
