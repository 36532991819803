import React from 'react';

const Video = ({ videoSrcURL, videoTitle, videoWidth }) => (
  <div className="w-full">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      className="w-full h-[300px] md:h-[500px] lg:h-[800px]"
      height="100%"
      width="100%"
      allow="accelerometer; autoplay; encrypted-media; gyroscope"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      autoplay="1"
      loading="eager"
    />
  </div>
);
export default Video;
