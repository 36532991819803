import React from 'react';

const Audio = ({ audioSrcURL, audioTitle }) => (
  <div className='w-full mb-6 lg:mb-12'>
    <h1>{audioTitle}</h1>
    <iframe
      width='100%'
      height='60'
      scrolling='no'
      frameborder='no'
      allow='autoplay'
      title={audioTitle}
      src={audioSrcURL}
      className='mt-2'
    ></iframe>
  </div>
);
export default Audio;
